"use client";

import {
  CreditsInfoDialog,
  SimpleCreditsInfoDialog,
} from "@/app/(app)/dashboard/credits-info/credits-info-dialog";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { SubscriptionContext } from "@/hooks/subscription-context";
import { getCreditsRemaning } from "@/lib/credit-utils-client";
import { type Subscriptions } from "@/utils/db/types";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { useContext } from "react";

function CreditsLowToolTip() {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button className="text-text-error">
            <ExclamationTriangleIcon />
          </button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Your credits are low.</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export function CreditBadge({ subscription }: { subscription: Subscriptions }) {
  const subscriptionContext = useContext(SubscriptionContext);
  const s = subscriptionContext?.subscription ?? subscription;

  const credits = {
    remaining: getCreditsRemaning(s),
    total: s.maxCredits + s.oneTimeCredits,
  };

  const percentage = (credits.remaining / credits.total) * 100;

  return (
    <Badge
      className="flex flex-col gap-sm items-center"
      variant={"brandhighlight"}
    >
      <div className="flex flex-row items-center gap-base">
        {percentage < 10 && <CreditsLowToolTip />}

        {subscription.simpleUI ? (
          <SimpleCreditsInfoDialog
            subscription={s}
            children={
              <span className="hover:underline cursor-auto hover:cursor-pointer">
                {credits.remaining} Credits Left
              </span>
            }
          />
        ) : (
          <CreditsInfoDialog
            showUsageLog={true}
            subscription={s}
            children={
              <span className="hover:underline cursor-auto hover:cursor-pointer">
                {credits.remaining} Credits Left
              </span>
            }
          />
        )}
      </div>
    </Badge>
  );
}
